import { MpApiIni, SetAuthHeader } from './api';

function getUserSoldTransactions() {
  return MpApiIni().get('/api/mp/transaction/v1/sold/transactions', SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getUserBoughtTransactions() {
  return MpApiIni().get('/api/mp/transaction/v1/bought/transactions', SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function createTransaction(productId) {
  return MpApiIni().post(`/api/mp/transaction/v1/transaction/${productId}`, null, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function makePayment(transactionId, paymentDetail) {
  return MpApiIni().post(`/api/mp/transaction/v1/transaction/${transactionId}/payment`, paymentDetail, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getTransactionDetail(transactionId) {
  return MpApiIni().get(`/api/mp/transaction/v1/${transactionId}/transaction`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function updateTransactionShipping(shippingDetail) {
  return MpApiIni().post('/api/mp/transaction/v1/shipping', shippingDetail, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function updateOrderReceived(transactionId) {
  return MpApiIni().put(`/api/mp/transaction/v1/shipping/${transactionId}/received`, null, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function retrievePayoutMethods() {
  return MpApiIni().get('/api/mp/transaction/v1/transaction/payoutmethods', SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function retrievePayoutRequiredField(payoutMethod) {
  return MpApiIni().get(`/api/mp/transaction/v1/transaction/payoutfields/${payoutMethod}`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

const services = {
  getUserSoldTransactions,
  getUserBoughtTransactions,
  createTransaction,
  getTransactionDetail,
  makePayment,
  updateTransactionShipping,
  updateOrderReceived,
  retrievePayoutMethods,
  retrievePayoutRequiredField,
};

export default services;
