<template>
  <div class="username" @click="redirectUserProfile">
    {{ profileDetail.userName }}
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  props: {
    profileDetail: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const redirectUserProfile = () => {
      router.push(`/user/${props.profileDetail.userId}/listings`);
    };

    return {
      redirectUserProfile,
    };
  },
};
</script>

<style lang="scss" scoped>
.username {
  cursor: pointer;
  text-decoration: underline;
  color: #3F51B5;
}
</style>
